.side-tab-container {
  height: 100vh; }

.side-tab-container .ant-tabs {
  height: 100%; }

.side-tab-container .ant-tabs .ant-tabs-nav {
  height: 64px;
  margin: 0; }

.side-tab-container .ant-tabs .ant-tabs-nav::before {
  border-bottom: 2px solid #808080; }

.side-tab-container .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list {
  width: 100%; }

.side-tab-container .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
  padding: 20px;
  margin: 0;
  width: 50%;
  font-size: x-large;
  display: flex;
  justify-content: center; }

.side-tab-container .ant-tabs .ant-tabs-content-holder {
  height: 93%; }

.side-tab-container .ant-tabs .ant-tabs-content-holder .ant-tabs-content {
  height: 100%; }
