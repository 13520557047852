.ready-container {
  width: 100%;
  height: 100%; }

.ready-container .ready-flex-box {
  position: relative;
  width: 100%;
  height: 100%; }

.ready-container .ready-flex-box .preview-video-layer {
  position: absolute;
  width: inherit;
  height: calc(100vh - 64px);
  z-index: 1; }

.ready-container .ready-flex-box .preview-video-layer video {
  width: 100%;
  height: 100%; }

.ready-container .ready-flex-box .dimmer-layer {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: calc(100vh - 64px);
  background: linear-gradient(to bottom, #999, #000);
  opacity: 0.8; }

.ready-container .ready-flex-box .interface-area {
  position: relative;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 100px; }

.ready-container .ready-flex-box .interface-area .interface-flex-box {
  text-align: center; }

.ready-container .ready-flex-box .interface-area .interface-flex-box .title-box .title {
  font-size: 32px;
  color: #fff; }

.ready-container .ready-flex-box .interface-area .interface-flex-box .nickname-input-box {
  margin-top: 20px; }

.ready-container .ready-flex-box .interface-area .interface-flex-box .nickname-input-box Input {
  text-align: center; }

.ready-container .ready-flex-box .interface-area .interface-flex-box .btn-box {
  margin-top: 15px; }

.ready-container .ready-flex-box .interface-area .interface-flex-box .media-control-box {
  margin-top: 25px;
  display: flex;
  justify-content: space-evenly; }

.media-option-card .ant-card-head {
  background-color: #888;
  color: #fff;
  font-weight: bold; }

.media-option-card .ant-card-body {
  padding: 0; }

.media-option-card:last-child {
  margin-top: 20px; }

.media-option-radio-group.ant-radio-group {
  display: flex;
  flex-direction: column; }
