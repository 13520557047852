@import url(//fonts.googleapis.com/earlyaccess/nanumgothic.css);
@charset "UTF-8";
@font-face {
  font-family: 'BC-EXTRA-BOLD';
  src: url(/static/media/BarlowCondensed-Black.e3108b49.ttf);
}

@font-face {
  font-family: 'BC-BOLD';
  src: url(/static/media/BarlowCondensed-Bold.e66bff88.ttf);
}

@font-face {
  font-family: 'BC-MEDIUM';
  src: url(/static/media/BarlowCondensed-Medium.a36059d8.ttf);
}

@font-face {
  font-family: 'BC-REGULAR';
  src: url(/static/media/BarlowCondensed-Regular.0bac2bb1.ttf);
}

@font-face {
  font-family: 'T-MONEY-BOLD';
  src: url(/static/media/TmoneyRoundWindExtraBold.9f066654.ttf);
}

body {
  margin: 0;
  font-family: 'Nanum Gothic', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a {
  font-family: 'NanumGothic' !important;
  color: '#4e4e4e' !important;
}

.shadow {
  box-shadow: 1px 2px 9px -6px rgba(0, 0, 0, 0.3);
}

/* FIXME ANTD Notificaition 위치 컴포넌트 내부 바인딩을 위한 속성
  변경필요하면 HENRY에게 문의좀 부탁드립니다.
*/
.ant-notification {
  position: absolute !important;
}

.w-100 {
  width: 100%;
}
.w-75 {
  width: 75%;
}
.w-50 {
  width: 50%;
}
.w-25 {
  width: 25%;
}

.h-100 {
  height: 100%;
}
.h-75 {
  height: 75%;
}
.h-50 {
  height: 50%;
}
.h-25 {
  height: 25%;
}

.text-center {
  text-align: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.text-black {
  color: black;
}



.cusorOver,
.hover-opacity {
  cursor: pointer;
}

.cusorOver:hover {
  background: #f1f1f1;
  opacity: 0.85;
}

.hover-opacity:hover {
  opacity: 0.7;
}
.signin-container {
  height: 100vh; }

.signin-container .signin-flex-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center; }

.signin-container .signin-flex-box .login-form-box {
  margin-top: 100px;
  width: 30%; }

.signin-container .signin-flex-box .login-form-box .image-box .logo {
  width: 100%;
  height: 100%;
  font-family: 'NanumGothic';
  font-weight: bold;
  font-size: 28px;
  color: #fff;
  padding: 0 1%; }

.signin-container .signin-flex-box .login-form-box .image-box .logo span {
  color: #476cec !important;
  -webkit-text-stroke: 0.5px #f1f1f1; }

.signin-container .signin-flex-box .login-form-box .ant-form {
  /* width: 30%; */
  background-color: white;
  padding: 1vw; }

.signin-container .signin-flex-box .login-form-box .login-option-interface {
  text-align: left; }

.signin-container
.signin-flex-box
.login-form-box
.login-option-interface
.login-form-forgot {
  float: right; }

.signin-container .signin-flex-box .login-form-box .login-form-button {
  width: 100%;
  margin-bottom: 1vh; }

.signup-container {
  height: 100vh;
}

.signup-container .signup-flex-box {
  height: 100%;
}

.signup-container .signup-flex-box .signup-form-box {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.signup-container .signup-flex-box .signup-form-box .image-box {
  width: 30%;
  /* background-color: #476cec !important; */
  margin: 1vh 0;
}

.signup-container .signup-flex-box .signup-form-box .image-box h1 {
  margin-top: 10vh;
  font-size: 27px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.04;
  letter-spacing: normal;
  text-align: left;
  color: #3589d4;
}

.signup-container .signup-flex-box .signup-form-box .image-box .logo {
  width: 100%;
  height: 100%;
  font-family: 'NanumGothic';
  font-weight: bold;
  font-size: 28px;
  color: #fff;
  padding: 0 1%;
}

.signup-container .signup-flex-box .signup-form-box .image-box .logo span {
  color: #476cec !important;
  -webkit-text-stroke: 0.5px #f1f1f1;
}

.signup-container .signup-flex-box .signup-form-box .ant-form {
  width: 30%;
  background-color: white;
  padding: 1vw;
}

.signup-container .signup-flex-box .signup-form-box .ant-form .ant-form-item {
  width: 100%;
  display: flex;
}

.signup-container
  .signup-flex-box
  .signup-form-box
  .ant-form
  .ant-form-item
  .ant-form-item-label {
  text-align: right;
  flex-basis: 30%;
}

.signup-container
  .signup-flex-box
  .signup-form-box
  .ant-form
  .ant-form-item
  .ant-form-item-control {
  flex-basis: 70%;
}

.signup-container
  .signup-flex-box
  .signup-form-box
  .ant-form
  .ant-form-item
  .ant-radio-group {
  width: 100%;
}

.signup-container
  .signup-flex-box
  .signup-form-box
  .ant-form
  .ant-form-item
  .ant-radio-group
  .ant-radio-button-wrapper {
  width: 50%;
  text-align: center;
}

.signup-container
  .signup-flex-box
  .signup-form-box
  .ant-form
  .ant-form-item
  .signup-form-button {
  width: 100%;
  background-color: #3589d4;
  border-radius: 2px;
  box-shadow: 0 1px 0.5px 0 rgba(0, 0, 0, 0.07);
}

.signup-container
  .signup-flex-box
  .signup-form-box
  .ant-form
  .ant-form-item
  .signup-form-reset {
  width: 100%;
  border-radius: 2px;
  box-shadow: 0 1px 0.5px 0 rgba(0, 0, 0, 0.07);
}

.ant-layout.main-layout {
  height: 100%;
  min-height: 100px;
  padding: 0 !important;
  background-color: #f0f2f5;
}

.ant-layout.main-layout .ant-layout-header {
  width: 100%;
  padding: 0;
  height: 70px;
  /* background-color: #fff; */
  background-color: #476cec;
  /* border-bottom: 2px solid #000; */
  color: white;
  box-shadow: 2px 9px -6px rgba(0, 0, 0, 0.3);
  
}


.header {
  width: 100%;
  height: 100%;
  /* height: 73px; */
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.ant-layout.main-layout .ant-layout-header .logo {
  width: 20%;
  height: 100%;
  font-family: 'NanumGothic';
  font-weight: bold;
  font-size: 28px;
  color: #fff;
  padding: 0 1%;
  /* -webkit-text-stroke: 2px #000; */
}
.ant-layout.main-layout .ant-layout-header .logo span {
  color: #476cec !important;
  -webkit-text-stroke: 0.5px #f1f1f1;
}
.ant-layout.main-layout .ant-layout-header .main-navigator {
  width: 60%;
  /* height: 100%; */
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-layout.main-layout .ant-layout-header .main-navigator .navi-item {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
  font-size: 1.05em;
  padding: 0 2%;
  border-top: 5px solid #476cec;
  border-bottom: 5px solid #476cec;
  margin-bottom: 7px;
  height: 67px;
  margin: 0;
}

.ant-layout.main-layout .ant-layout-header .main-navigator .navi-item.active {
  border-color: white;
  border-top: 5px solid #476cec;
}

/* .ant-layout.main-layout .ant-layout-header .header-menu .ant-menu { */
.ant-layout.main-layout .ant-layout-header .ant-menu {
  height: 100%;
  background: transparent;
  display: flex;
  justify-content: end;
}

.ant-layout.main-layout .ant-layout-header .ant-menu #accountBtn .anticon {
  margin-right: 0;
}

.main-layout-content {
  width: 60%;
  /* display: flex; */
  justify-content: center;
  margin: 0 auto;
}

.main-icon-img {
  width: 32px;
  height: 32px;
  background-size: cover;
  margin-right: 10px;
}

/* 모바일 화면에서만 보이지 않도록 설정 */
@media only screen and (max-width: 767px) {
  .logo {
    display: none;
  }
  .main-icon-img {
    font-size: 24px;
  }
} 
.confenrence-layout {
  height: 100vh; }
  .confenrence-layout .broadcast-page-header {
    display: flex;
    align-items: center;
    padding: 0px 36px; }
    .confenrence-layout .broadcast-page-header .ant-page-header-heading {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center; }
      .confenrence-layout .broadcast-page-header .ant-page-header-heading .ant-page-header-heading-left .ant-page-header-back .ant-page-header-back-button {
        color: #fff; }
      .confenrence-layout .broadcast-page-header .ant-page-header-heading .ant-page-header-heading-left .ant-page-header-heading-title {
        margin-left: 12px;
        color: #fff; }
      .confenrence-layout .broadcast-page-header .ant-page-header-heading .ant-page-header-heading-extra {
        height: 100%;
        display: flex;
        align-items: center; }
  .confenrence-layout aside.left-side-bar div.ant-layout-sider-children ul.ant-menu li.ant-menu-item.ant-menu-item-selected {
    background-color: inherit; }
  .confenrence-layout aside.left-side-bar div.ant-layout-sider-children ul.ant-menu li.ant-menu-item .icon {
    font-size: 24px; }
  .confenrence-layout aside.left-inner-side-bar {
    min-width: -webkit-fit-content !important;
    min-width: -moz-fit-content !important;
    min-width: fit-content !important;
    max-width: -webkit-fit-content !important;
    max-width: -moz-fit-content !important;
    max-width: fit-content !important; }
  .confenrence-layout aside.right-side-bar {
    min-width: -webkit-fit-content !important;
    min-width: -moz-fit-content !important;
    min-width: fit-content !important;
    max-width: -webkit-fit-content !important;
    max-width: -moz-fit-content !important;
    max-width: fit-content !important; }

.chatting-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .chatting-container .chat-board-container {
    flex-basis: 100%;
    overflow-y: auto; }
    .chatting-container .chat-board-container .chat-board-box {
      height: 100%; }
      .chatting-container .chat-board-container .chat-board-box .chat-content-box {
        display: flex;
        justify-content: flex-start;
        padding: 0 10px; }
        .chatting-container .chat-board-container .chat-board-box .chat-content-box .chat-content {
          max-width: 80%;
          padding: 20px 30px;
          background-color: #d9d9d9;
          border-radius: 20px;
          color: black; }
          .chatting-container .chat-board-container .chat-board-box .chat-content-box .chat-content .chat-user {
            display: block;
            font-family: 'BC-BOLD';
            font-size: 16px; }
          .chatting-container .chat-board-container .chat-board-box .chat-content-box .chat-content .chat-text {
            line-break: anywhere; }
        .chatting-container .chat-board-container .chat-board-box .chat-content-box.me {
          justify-content: flex-end; }
          .chatting-container .chat-board-container .chat-board-box .chat-content-box.me .chat-content {
            background-color: #1890ff;
            color: #fff; }
  .chatting-container .chat-input-container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
    .chatting-container .chat-input-container .chat-input-box {
      display: flex; }
      .chatting-container .chat-input-container .chat-input-box .chat-input-area {
        width: 90%; }
        .chatting-container .chat-input-container .chat-input-box .chat-input-area #chatText {
          height: 48px; }
      .chatting-container .chat-input-container .chat-input-box .chat-send-button-area {
        width: 10%; }
        .chatting-container .chat-input-container .chat-input-box .chat-send-button-area #sendButton {
          height: 100%; }

.file-list-container {
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 100%; }
  .file-list-container .file-list-flex-box {
    height: 200px;
    padding: 5px 10px; }
    .file-list-container .file-list-flex-box .file-list-box {
      padding-right: 5px;
      height: 100%;
      overflow-y: auto; }
      .file-list-container .file-list-flex-box .file-list-box .empty-set-box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%; }
        .file-list-container .file-list-flex-box .file-list-box .empty-set-box .empty-set {
          color: #fff; }
      .file-list-container .file-list-flex-box .file-list-box .list-item {
        margin-bottom: 3px;
        width: 100%;
        padding: 20px;
        background-color: #fff;
        cursor: pointer; }
        .file-list-container .file-list-flex-box .file-list-box .list-item .list-title {
          width: 100%;
          color: #8e8e8e;
          font-weight: bold;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap; }
        .file-list-container .file-list-flex-box .file-list-box .list-item.active {
          background-color: #7cb305; }
          .file-list-container .file-list-flex-box .file-list-box .list-item.active .list-title {
            color: #fff; }
        .file-list-container .file-list-flex-box .file-list-box .list-item:hover {
          background-color: #1890ff; }
          .file-list-container .file-list-flex-box .file-list-box .list-item:hover .list-title {
            color: #fff; }
  .file-list-container .document-image-flex-box {
    flex: 1 1;
    z-index: 1;
    overflow-y: auto;
    padding: 5px 10px;
    background-color: #4b4b4b; }
    .file-list-container .document-image-flex-box .image-row {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      padding: 7px 8px; }
      .file-list-container .document-image-flex-box .image-row .image-box {
        position: relative;
        border: 1px solid #bbb;
        margin-bottom: 10px;
        cursor: pointer;
        text-align: center;
        background-color: #fff; }
        .file-list-container .document-image-flex-box .image-row .image-box img {
          background-color: #fff;
          width: 100%; }
        .file-list-container .document-image-flex-box .image-row .image-box.moderator {
          border: 5px solid #c75c58; }
        .file-list-container .document-image-flex-box .image-row .image-box.me {
          border: 5px solid #5e8d15; }
        .file-list-container .document-image-flex-box .image-row .image-box .video-badge {
          color: #fff;
          padding: 4px 8px; }
        .file-list-container .document-image-flex-box .image-row .image-box .my-badge {
          position: absolute;
          top: 0px;
          left: 0px;
          background-color: #5e8d15; }
        .file-list-container .document-image-flex-box .image-row .image-box .moderator-badge {
          position: absolute;
          top: 0px;
          right: 0px;
          background-color: #c75c58; }
    .file-list-container .document-image-flex-box .empty-set-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%; }
      .file-list-container .document-image-flex-box .empty-set-box .empty-set {
        color: #fff; }
    .file-list-container .document-image-flex-box .collapse-toggle-button-box {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%); }
  .file-list-container .ant-list-item {
    cursor: pointer;
    background-color: #fff; }
    .file-list-container .ant-list-item:hover {
      background-color: #1890ff;
      color: #fff; }
    .file-list-container .ant-list-item.active {
      background-color: #7cb305;
      color: #fff; }

.voting-container {
  width: 400px;
  height: 100%; }
  .voting-container .voting-flex-box {
    display: flex;
    flex-direction: column;
    height: 100%; }
    .voting-container .voting-flex-box .insert-voting-box {
      min-height: 110px;
      max-height: 200px;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      overflow-y: auto;
      padding: 14px 8px;
      background-color: #4a4a4a; }
      .voting-container .voting-flex-box .insert-voting-box .question-box {
        display: flex;
        height: 85px; }
        .voting-container .voting-flex-box .insert-voting-box .question-box .left-item {
          height: 100%;
          flex: 1 1;
          display: flex;
          flex-direction: column; }
          .voting-container .voting-flex-box .insert-voting-box .question-box .left-item .input-box {
            width: 100%;
            height: 50%; }
            .voting-container .voting-flex-box .insert-voting-box .question-box .left-item .input-box .insert-text-field {
              width: 100%;
              height: 100%;
              border: none;
              color: black;
              padding: 0 24px; }
              .voting-container .voting-flex-box .insert-voting-box .question-box .left-item .input-box .insert-text-field::placeholder {
                color: #939393; }
          .voting-container .voting-flex-box .insert-voting-box .question-box .left-item .insert-box {
            width: 100%;
            height: 50%; }
            .voting-container .voting-flex-box .insert-voting-box .question-box .left-item .insert-box .insert-btn {
              border: none;
              width: 100%;
              height: 100%;
              color: #545454; }
        .voting-container .voting-flex-box .insert-voting-box .question-box .right-item {
          width: 75px;
          height: 100%;
          background-color: #4457f3;
          display: flex;
          align-items: center;
          cursor: pointer; }
          .voting-container .voting-flex-box .insert-voting-box .question-box .right-item .insert-icon {
            width: 100%;
            color: #fff; }
      .voting-container .voting-flex-box .insert-voting-box .option-box-container {
        margin-top: 10px;
        padding: 0 5px; }
        .voting-container .voting-flex-box .insert-voting-box .option-box-container .option-wrapper {
          display: flex;
          flex-wrap: wrap; }
          .voting-container .voting-flex-box .insert-voting-box .option-box-container .option-wrapper .option .option-checkbox {
            display: flex;
            align-items: center; }
            .voting-container .voting-flex-box .insert-voting-box .option-box-container .option-wrapper .option .option-checkbox .option-text {
              color: #fff; }
    .voting-container .voting-flex-box .voting-list-box {
      flex: 1 1;
      overflow-y: auto;
      padding: 14px 8px; }
      .voting-container .voting-flex-box .voting-list-box .voting-item {
        margin-bottom: 10px;
        background-color: #fff;
        color: black;
        cursor: pointer; }
        .voting-container .voting-flex-box .voting-list-box .voting-item:hover .title-box {
          background-color: #1890ff;
          color: #fff; }
        .voting-container .voting-flex-box .voting-list-box .voting-item .title-box {
          padding: 0px 16px;
          padding-top: 20px;
          padding-bottom: 10px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px; }
          .voting-container .voting-flex-box .voting-list-box .voting-item .title-box .icon {
            font-size: 16px; }
        .voting-container .voting-flex-box .voting-list-box .voting-item .option-box {
          padding: 0px 16px;
          padding-bottom: 20px; }

.drawing-container {
  width: 200px;
  height: 100%; }
  .drawing-container .drawing-flex-box {
    width: 100%;
    height: 100%; }
    .drawing-container .drawing-flex-box .sheet-list {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      padding: 20px 13px; }
      .drawing-container .drawing-flex-box .sheet-list .sheet-box {
        width: 100%;
        height: 110px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        cursor: pointer; }
        .drawing-container .drawing-flex-box .sheet-list .sheet-box.me-active {
          border: 5px solid #5e8d15; }
        .drawing-container .drawing-flex-box .sheet-list .sheet-box.moderate-active {
          border: 5px solid #c75c58; }
        .drawing-container .drawing-flex-box .sheet-list .sheet-box .thumbnail-box {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center; }
      .drawing-container .drawing-flex-box .sheet-list .add-sheet-item {
        box-shadow: 0 0 0 5px #fff inset;
        background-color: #848484; }
        .drawing-container .drawing-flex-box .sheet-list .add-sheet-item .add-mark {
          font-size: 42px; }

::-webkit-scrollbar-track {
  background-color: none; }

::-webkit-scrollbar {
  width: 6px; }

::-webkit-scrollbar-thumb {
  width: 4px;
  background-color: #d6d6d6; }

.side-tab-container {
  height: 100%; }

.side-tab-container .ant-tabs {
  height: 100%; }

.side-tab-container .ant-tabs .ant-tabs-nav {
  height: 7%;
  margin: 0; }

.side-tab-container .ant-tabs .ant-tabs-nav::before {
  border-bottom: 2px solid #808080; }

.side-tab-container .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list {
  width: 100%; }

.side-tab-container .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
  padding: 20px;
  margin: 0;
  width: 50%;
  font-size: x-large;
  display: flex;
  justify-content: center; }

.side-tab-container .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
  color: #b2b2b2; }

.side-tab-container .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
  font-weight: bold; }

.side-tab-container .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-ink-bar {
  background-color: #fff;
  height: 4px; }

.side-tab-container .ant-tabs .ant-tabs-content-holder {
  height: 93%; }

.side-tab-container .ant-tabs .ant-tabs-content-holder .ant-tabs-content {
  height: 100%; }

.side-tab-container .chatting-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; }

.side-tab-container .chatting-container .chat-board-container {
  flex: 1 1; }

.side-tab-container .chatting-container .chat-board-container .chat-board-box {
  height: 100%;
  overflow-y: auto; }

.side-tab-container .chatting-container .chat-board-container .chat-board-box .chat-content-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px; }

.side-tab-container .chatting-container .chat-board-container .chat-board-box .chat-content-box .thumb-box {
  font-size: 15px;
  background-color: #4457f3;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-right: 20px; }

.side-tab-container .chatting-container .chat-board-container .chat-board-box .chat-content-box .chat-content {
  max-width: 80%;
  padding: 14px;
  background-color: #e6ebf1;
  border-radius: 20px;
  color: #32325d; }

.side-tab-container .chatting-container .chat-board-container .chat-board-box .chat-content-box .chat-content .chat-user {
  display: block;
  font-family: 'BC-BOLD';
  font-size: 16px; }

.side-tab-container .chatting-container .chat-board-container .chat-board-box .chat-content-box .chat-content .chat-text {
  line-break: anywhere; }

.side-tab-container .chatting-container .chat-board-container .chat-board-box .chat-content-box.me {
  justify-content: flex-end; }

.side-tab-container .chatting-container .chat-board-container .chat-board-box .chat-content-box.me .chat-content {
  background-color: #47b6f9;
  color: #fff; }

.side-tab-container .chatting-container .chat-input-container {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }

.side-tab-container .chatting-container .chat-input-container .chat-input-box {
  display: flex; }

.side-tab-container .chatting-container .chat-input-container .chat-input-box .chat-input-area {
  width: 90%; }

.side-tab-container .chatting-container .chat-input-container .chat-input-box .chat-input-area #chatText {
  height: 48px; }

.side-tab-container .chatting-container .chat-input-container .chat-input-box .chat-send-button-area {
  width: 10%; }

.side-tab-container .chatting-container .chat-input-container .chat-input-box .chat-send-button-area #sendButton {
  height: 100%; }

.side-tab-container .participants-container {
  height: 100%; }

.side-tab-container .participants-container .participants-flex-box {
  height: 100%; }

.side-tab-container .participants-container .participants-flex-box .user-list-box {
  height: 100%;
  padding: 20px 15px;
  overflow-y: scroll; }

.side-tab-container .participants-container .participants-flex-box .user-list-box .user-info {
  display: flex;
  align-items: center;
  margin-bottom: 24px; }

.side-tab-container .participants-container .participants-flex-box .user-list-box .user-info .thumb-box {
  border-radius: 100%;
  width: 50px;
  height: 50px;
  border: 2px solid black;
  background-color: #ffbd12;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 24px; }

.side-tab-container .participants-container .participants-flex-box .user-list-box .user-info .info-box {
  margin-left: 10px; }

.side-tab-container .participants-container .participants-flex-box .user-list-box .user-info .info-box .name {
  font-size: 24px;
  font-weight: bolder; }

.side-tab-container .participants-container .participants-flex-box .user-list-box .user-info .info-box .sub {
  font-size: 16px; }

.join-container {
  height: calc(100vh - 64px); }
  .join-container .join-flex-box {
    height: 100%;
    display: flex;
    justify-content: center; }
    .join-container .join-flex-box .option-select-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 40%;
      height: 100%; }
      .join-container .join-flex-box .option-select-box .room-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer; }
        .join-container .join-flex-box .option-select-box .room-box img {
          width: 150%; }
        .join-container .join-flex-box .option-select-box .room-box .menu-title {
          font-family: 'T-MONEY-BOLD';
          font-size: 32px; }

.setting-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 64px); }

.setting-container .setting-flex-box {
  padding: 20%;
  padding-top: 100px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden; }

.setting-container .setting-flex-box .category-area {
  margin-bottom: 48px;
  width: 100%;
  display: flex;
  justify-content: flex-start; }

.setting-container .setting-flex-box .category-area .step-title {
  width: 30%;
  font-size: 48px;
  font-weight: bolder; }

.setting-container .setting-flex-box .category-area .step-content {
  width: 60%;
  padding-left: 40px; }

.setting-container .setting-flex-box .category-area .step-content .ant-radio-group {
  display: flex;
  justify-content: space-around; }

.setting-container .setting-flex-box .category-area .step-content .ant-radio-group .category-box .ant-radio-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; }

.setting-container .setting-flex-box .category-area .step-content .ant-radio-group .category-box .ant-radio-wrapper .category-content .category-image {
  width: 200px;
  height: 180px;
  border-radius: 20px;
  display: flex;
  align-items: center; }

.setting-container .setting-flex-box .category-area .step-content .ant-radio-group .category-box .ant-radio-wrapper .category-content .category-image img {
  width: 100%; }

.setting-container .setting-flex-box .category-area .step-content .ant-radio-group .category-box .ant-radio-wrapper .category-content .category-name {
  text-align: center;
  font-size: 40px;
  font-weight: bold; }

.setting-container .setting-flex-box .category-area .step-content .ant-radio-group .category-box .ant-radio-wrapper .ant-radio {
  margin-bottom: 10px; }

.setting-container .setting-flex-box .category-area .step-content .ant-radio-group .category-box .ant-radio-wrapper .ant-radio .ant-radio-inner {
  width: 32px;
  height: 32px; }

.setting-container .setting-flex-box .category-area .step-content .ant-radio-group .category-box .ant-radio-wrapper .ant-radio .ant-radio-inner::after {
  top: 4px;
  left: 4px;
  width: 23px;
  height: 23px;
  border-radius: 23px; }

.setting-container .setting-flex-box .hr-line {
  margin: 40px 0; }

.setting-container .footer-interface-box {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 20px 40px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: #333; }

.setting-container .footer-interface-box Button {
  font-weight: bold; }

.setting-container .question-area .question-addition-button-area #addButton {
  width: 100%;
  height: 100px;
  font-size: 24px;
  color: #aaa;
  font-weight: bold; }

.setting-container #dndBox {
  display: flex; }

.calendar-divider::before {
  width: 0 !important;
}

.conferenceCreate-userItem {
  color: #333;
}
.conferenceCreate-userItem:hover {
  background: rgba(74, 140, 255, 0.14);
}

.conferenceCreate-userItem-selected {
  background: rgba(60, 127, 241, 0.14);
}

.conferenceCreate-userItem {
  color: #333;
}
.conferenceCreate-userItem:hover {
  background: rgba(74, 140, 255, 0.14);
}

.conferenceCreate-userItem-selected {
  background: rgba(60, 127, 241, 0.14);
}

.App {
  font-family: sans-serif;
  text-align: center;
}
.landing-wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(51, 51, 51);
  color: white;
}
.landing-wrapper .landing-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.landing-wrapper .landing-container .landing-header {
  width: 60%;
  height: 5vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}

.landing-wrapper .landing-container .landing-header .logo {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.landing-wrapper .landing-container .landing-header .login {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.landing-wrapper .landing-container .visual-container {
  width: 60%;
  height: 60vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-wrapper .landing-container .visual-container .left {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.landing-wrapper .landing-container .visual-container .right {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-wrapper .landing-container .banner-container {
  width: 100%;
  height: 10vh;
  background-color: aliceblue;
  color: black;
}

.landing-wrapper .landing-container .banner-container .banner-content {
  width: 60%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-wrapper .landing-container .banner-container .banner-content .left {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.landing-wrapper .landing-container .banner-container .banner-content .right {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1vw;
}

.landing-wrapper .landing-container .description {
  width: 60%;
  height: 80vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-wrapper .landing-container .letter {
  width: 100%;
  height: 30vh;
  margin: 0 auto;
  background-color: aliceblue;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-wrapper .landing-container .footer {
  width: 60%;
  height: 5vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.join-container {
  height: calc(100vh - 164px); }
  .join-container .join-flex-box {
    height: 100%;
    padding-top: 5%;
    display: flex;
    justify-content: center; }
    .join-container .join-flex-box .option-view {
      display: inline;
      justify-content: space-between;
      padding-left: 20px;
      align-items: center;
      width: 50%;
      height: 100%; }
    .join-container .join-flex-box .option-select-box {
      display: inline;
      justify-content: space-between;
      align-items: center;
      width: 20%;
      height: 100%; }
      .join-container .join-flex-box .option-select-box .room-box {
        display: block;
        flex-direction: column;
        align-items: center;
        cursor: pointer; }
        .join-container .join-flex-box .option-select-box .room-box img {
          width: 10%; }
        .join-container .join-flex-box .option-select-box .room-box .menu-title {
          font-family: 'T-MONEY-BOLD';
          font-size: 18px; }

.join-container {
  height: calc(100vh - 164px); }
  .join-container .join-flex-box {
    height: 100%;
    padding-top: 5%;
    display: flex;
    justify-content: center; }
    .join-container .join-flex-box .option-view {
      display: inline;
      justify-content: space-between;
      padding-left: 20px;
      align-items: center;
      width: 50%;
      height: 100%; }
    .join-container .join-flex-box .option-select-box {
      display: inline;
      justify-content: space-between;
      align-items: center;
      width: 20%;
      height: 100%; }
      .join-container .join-flex-box .option-select-box .room-box {
        display: block;
        flex-direction: column;
        align-items: center;
        cursor: pointer; }
        .join-container .join-flex-box .option-select-box .room-box img {
          width: 10%; }
        .join-container .join-flex-box .option-select-box .room-box .menu-title {
          font-family: 'T-MONEY-BOLD';
          font-size: 18px; }

.conference-container {
  height: 100%;
  display: flex; }

.conference-container .media-box {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column; }

.conference-container .media-box .chat-toggle-button {
  position: absolute;
  top: 20px;
  right: 20px; }

.conference-container .media-box .video-box {
  width: 100%;
  height: 90%;
  background-color: black; }

.conference-container .media-box .interface-box {
  width: 100%;
  min-height: 10%; }

.conference-container .side-tab {
  width: 30%;
  height: 100%;
  position: relative;
  display: none; }

.conference-container .side-tab.toggle {
  display: block; }

.conference-container .interface-container {
  width: 100%;
  height: 100%;
  background-color: #434343;
  position: relative; }

.conference-container .interface-container .interface-content-box {
  padding: 20px;
  display: flex;
  justify-content: center; }

.conference-container .interface-container .interface-content-box .interface-button-group {
  display: flex;
  justify-content: space-around; }

.conference-container .interface-container .interface-content-box .interface-button-group .interface-button {
  height: 48px;
  margin: 0 10px;
  border-radius: 4px;
  background-color: #fff;
  font-weight: bold;
  padding: 7px 30px; }

.conference-container .interface-container .interface-content-box .interface-button-group .interface-button.active {
  border-color: #fff;
  background-color: inherit; }

.conference-container .interface-container .interface-content-box .interface-button-group .interface-button.mute {
  color: #fff;
  background-color: inherit;
  border: 1px soild #fff; }

.conference-container .interface-container .interface-right-box {
  position: absolute;
  right: 40px;
  top: 20px; }

#invitePopover .invite-btn-box {
  display: flex;
  width: 300px;
  cursor: pointer; }

#invitePopover .invite-btn-box .text-field {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  background-color: #1890ff;
  color: #fff;
  width: 90%;
  padding-left: 5px; }

#invitePopover .invite-btn-box .copy-btn button {
  border-radius: 0; }

.media-content-container {
  height: 100%; }

.media-content-container .media-video {
  width: 100%;
  height: 100%; }

.media-content-container .user-video-box {
  position: relative;
  padding: 20px;
  display: flex;
  justify-content: center; }

.media-content-container .user-video-box video {
  max-width: 100%;
  height: 100%; }

.media-content-container .participants-video-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }

.media-content-container .participants-video-box .video-wrapper {
  position: relative;
  height: 100%;
  padding: 20px; }

.media-content-container .participants-video-box .video-wrapper .label-area {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: black;
  font-size: x-large;
  padding: 0px 20px; }

.media-content-container .participants-video-box .video-wrapper .label-area .display-name-text {
  color: white;
  font-family: 'BC-BOLD'; }

.media-content-container .participants-video-box .video-wrapper .user-video {
  max-width: 100%;
  height: 100%; }

.media-content-container .single-video-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }

.media-content-container .single-video-layout #media1 {
  width: 70%;
  height: 70%; }

.media-content-container .couple-video-layout {
  display: flex;
  align-items: center;
  height: 100%; }

.media-content-container .couple-video-layout .user-video-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 50%; }

.media-content-container .couple-video-layout .participants-video-box {
  width: 50%;
  height: 50%; }

.media-content-container .trio-video-layout {
  display: flex;
  width: 100%;
  height: 100%; }

.media-content-container .trio-video-layout .left-side {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.media-content-container .trio-video-layout .left-side .user-video-box {
  height: 500px; }

.media-content-container .trio-video-layout .right-side {
  width: 50%;
  height: 100%; }

.media-content-container .trio-video-layout .right-side .participants-video-box {
  width: 100%;
  height: 50%; }

.media-content-container .quattro-video-layout {
  display: flex;
  flex-wrap: wrap;
  height: 100%; }

.media-content-container .quattro-video-layout .user-video-box {
  flex-basis: 50%;
  height: 50%; }

.media-content-container .quattro-video-layout .participants-video-box {
  flex-basis: 50%;
  height: 50%; }

.media-content-container .document-sharing-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; }

.media-content-container .document-sharing-layout #mediaDocument {
  width: 100%;
  height: 80%;
  background-color: #757575;
  position: relative;
  display: flex;
  justify-content: center; }

.media-content-container .document-sharing-layout #mediaDocument .document-image {
  background-color: #fff;
  height: 100%; }

.media-content-container .document-sharing-layout #mediaDocument .empty-document-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 40%;
  background-color: #fff;
  font-size: xx-large; }

.media-content-container .document-sharing-layout #mediaDocument .canvas-stage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.media-content-container .document-sharing-layout .participant-video {
  width: 100%;
  height: 20%;
  display: flex;
  overflow-x: auto; }

.media-content-container .document-sharing-layout .participant-video .participants-video-box {
  height: 100%; }

.media-content-container .document-sharing-layout .owner-video-box {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: black;
  width: 300px;
  height: 200px; }

.media-content-container .document-sharing-layout .owner-video-box .user-video {
  width: 100%;
  height: 100%; }

.media-content-container .drawing-board-layout {
  width: 100%;
  height: 100%; }

.media-content-container .drawing-board-layout .drawing-board {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #8f8f8f; }

.media-content-container .drawing-board-layout .drawing-board .canvas {
  width: 90%;
  height: 90%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center; }

.media-content-container .drawing-board-layout .drawing-board .canvas .active-sheet-not-exist-alert {
  background-color: #fff;
  padding: 40px 60px;
  border-radius: 4px; }

.media-content-container .drawing-board-layout .drawing-board .canvas .active-sheet-not-exist-alert .info-text {
  font-size: 18px; }

.media-content-container .drawing-board-layout .drawing-board .canvas .canvas-stage {
  background-color: #fff;
  box-shadow: 0 0 5px 1px #aaa inset; }

.media-content-container .drawing-board-layout .drawing-board .video-area {
  position: absolute;
  bottom: 0; }

.media-content-container .drawing-board-layout .drawing-board .video-area .video-toggle-btn-box {
  display: flex;
  justify-content: center; }

.media-content-container .drawing-board-layout .drawing-board .video-area .video-toggle-btn-box .toggle-button {
  font-size: 32px; }

.media-content-container .drawing-board-layout .drawing-board .video-area .video-toggle-btn-box .toggle-button.collapsed {
  margin-bottom: 10px; }

.media-content-container .drawing-board-layout .drawing-board .video-area .participant-video {
  width: 100%;
  height: 200px;
  display: flex;
  overflow-x: auto; }

.media-content-container .drawing-board-layout .drawing-board .video-area .participant-video .participants-video-box {
  height: 100%; }

.media-content-container .drawing-tools-contaier {
  position: absolute;
  background-color: #6480e3;
  border-radius: 50px;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2; }

.media-content-container .drawing-tools-contaier .drawing-tools-flex-box {
  padding: 12px;
  display: flex; }

.media-content-container .drawing-tools-contaier .drawing-tools-flex-box div.menu-box {
  display: flex; }

.media-content-container .drawing-tools-contaier .drawing-tools-flex-box div.menu-box .menu {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  color: #fff;
  cursor: pointer; }

.media-content-container .drawing-tools-contaier .drawing-tools-flex-box div.menu-box .menu:last-child {
  margin-right: 0; }

.media-content-container .drawing-tools-contaier .drawing-tools-flex-box .history-menu .menu {
  background-color: #fff;
  border-radius: 100%; }

.media-content-container .drawing-tools-contaier .drawing-tools-flex-box .history-menu .menu .history-item {
  font-size: 20px;
  color: #6480e3; }

.media-content-container .drawing-tools-contaier .drawing-tools-flex-box .color-menu .menu .color-item {
  width: inherit;
  height: inherit;
  border-radius: 100%; }

.media-content-container .drawing-tools-contaier .drawing-tools-flex-box .color-menu .menu .color-item.active {
  box-shadow: 0 0 0 4px inset; }

.media-content-container .drawing-tools-contaier .drawing-tools-flex-box .color-menu .menu #red {
  background-color: red; }

.media-content-container .drawing-tools-contaier .drawing-tools-flex-box .color-menu .menu #orange {
  background-color: #ff8b50; }

.media-content-container .drawing-tools-contaier .drawing-tools-flex-box .color-menu .menu #yellow {
  background-color: #f5c43f; }

.media-content-container .drawing-tools-contaier .drawing-tools-flex-box .color-menu .menu #green {
  background-color: #7cc728; }

.media-content-container .drawing-tools-contaier .drawing-tools-flex-box .color-menu .menu #blue {
  background-color: #5050ff; }

.media-content-container .drawing-tools-contaier .drawing-tools-flex-box .color-menu .menu #pink {
  background-color: #ff50ee; }

.media-content-container .drawing-tools-contaier .drawing-tools-flex-box .vertical-hr {
  margin: 0 10px;
  height: 35px;
  border-left: 1px solid #4f65b1; }

.media-content-container .drawing-tools-contaier .drawing-tools-flex-box .interface-menu #closeBtn {
  font-size: 40px;
  color: #ec5a5a;
  background-color: #fff;
  border-radius: 100%; }

.media-content-container .drawing-tools-toggle {
  position: absolute;
  left: 50%;
  z-index: 5; }

.media-content-container .drawing-tools-toggle .panel-toggle-button {
  font-size: 56px; }

.ready-container {
  width: 100%;
  height: 100%; }

.ready-container .ready-flex-box {
  position: relative;
  width: 100%;
  height: 100%; }

.ready-container .ready-flex-box .preview-video-layer {
  position: absolute;
  width: inherit;
  height: calc(100vh - 64px);
  z-index: 1; }

.ready-container .ready-flex-box .preview-video-layer video {
  width: 100%;
  height: 100%; }

.ready-container .ready-flex-box .dimmer-layer {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: calc(100vh - 64px);
  background: linear-gradient(to bottom, #999, #000);
  opacity: 0.8; }

.ready-container .ready-flex-box .interface-area {
  position: relative;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 100px; }

.ready-container .ready-flex-box .interface-area .interface-flex-box {
  text-align: center; }

.ready-container .ready-flex-box .interface-area .interface-flex-box .title-box .title {
  font-size: 32px;
  color: #fff; }

.ready-container .ready-flex-box .interface-area .interface-flex-box .nickname-input-box {
  margin-top: 20px; }

.ready-container .ready-flex-box .interface-area .interface-flex-box .nickname-input-box Input {
  text-align: center; }

.ready-container .ready-flex-box .interface-area .interface-flex-box .btn-box {
  margin-top: 15px; }

.ready-container .ready-flex-box .interface-area .interface-flex-box .media-control-box {
  margin-top: 25px;
  display: flex;
  justify-content: space-evenly; }

.media-option-card .ant-card-head {
  background-color: #888;
  color: #fff;
  font-weight: bold; }

.media-option-card .ant-card-body {
  padding: 0; }

.media-option-card:last-child {
  margin-top: 20px; }

.media-option-radio-group.ant-radio-group {
  display: flex;
  flex-direction: column; }

.side-tab-container {
  height: 100vh; }

.side-tab-container .ant-tabs {
  height: 100%; }

.side-tab-container .ant-tabs .ant-tabs-nav {
  height: 64px;
  margin: 0; }

.side-tab-container .ant-tabs .ant-tabs-nav::before {
  border-bottom: 2px solid #808080; }

.side-tab-container .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list {
  width: 100%; }

.side-tab-container .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
  padding: 20px;
  margin: 0;
  width: 50%;
  font-size: x-large;
  display: flex;
  justify-content: center; }

.side-tab-container .ant-tabs .ant-tabs-content-holder {
  height: 93%; }

.side-tab-container .ant-tabs .ant-tabs-content-holder .ant-tabs-content {
  height: 100%; }

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fade-in {
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* ===== ===== */
.slide-in-top {
  animation: slide-in-top 0.45s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@keyframes slide-in-top {
  0% {
    transform: translateY(-30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

