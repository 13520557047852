.signup-container {
  height: 100vh;
}

.signup-container .signup-flex-box {
  height: 100%;
}

.signup-container .signup-flex-box .signup-form-box {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.signup-container .signup-flex-box .signup-form-box .image-box {
  width: 30%;
  /* background-color: #476cec !important; */
  margin: 1vh 0;
}

.signup-container .signup-flex-box .signup-form-box .image-box h1 {
  margin-top: 10vh;
  font-size: 27px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.04;
  letter-spacing: normal;
  text-align: left;
  color: #3589d4;
}

.signup-container .signup-flex-box .signup-form-box .image-box .logo {
  width: 100%;
  height: 100%;
  font-family: 'NanumGothic';
  font-weight: bold;
  font-size: 28px;
  color: #fff;
  padding: 0 1%;
}

.signup-container .signup-flex-box .signup-form-box .image-box .logo span {
  color: #476cec !important;
  -webkit-text-stroke: 0.5px #f1f1f1;
}

.signup-container .signup-flex-box .signup-form-box .ant-form {
  width: 30%;
  background-color: white;
  padding: 1vw;
}

.signup-container .signup-flex-box .signup-form-box .ant-form .ant-form-item {
  width: 100%;
  display: flex;
}

.signup-container
  .signup-flex-box
  .signup-form-box
  .ant-form
  .ant-form-item
  .ant-form-item-label {
  text-align: right;
  flex-basis: 30%;
}

.signup-container
  .signup-flex-box
  .signup-form-box
  .ant-form
  .ant-form-item
  .ant-form-item-control {
  flex-basis: 70%;
}

.signup-container
  .signup-flex-box
  .signup-form-box
  .ant-form
  .ant-form-item
  .ant-radio-group {
  width: 100%;
}

.signup-container
  .signup-flex-box
  .signup-form-box
  .ant-form
  .ant-form-item
  .ant-radio-group
  .ant-radio-button-wrapper {
  width: 50%;
  text-align: center;
}

.signup-container
  .signup-flex-box
  .signup-form-box
  .ant-form
  .ant-form-item
  .signup-form-button {
  width: 100%;
  background-color: #3589d4;
  border-radius: 2px;
  box-shadow: 0 1px 0.5px 0 rgba(0, 0, 0, 0.07);
}

.signup-container
  .signup-flex-box
  .signup-form-box
  .ant-form
  .ant-form-item
  .signup-form-reset {
  width: 100%;
  border-radius: 2px;
  box-shadow: 0 1px 0.5px 0 rgba(0, 0, 0, 0.07);
}
