@charset "UTF-8";
@import url(//fonts.googleapis.com/earlyaccess/nanumgothic.css);
@font-face {
  font-family: 'BC-EXTRA-BOLD';
  src: url('./assets/fonts/BarlowCondensed-Black.ttf');
}

@font-face {
  font-family: 'BC-BOLD';
  src: url('./assets/fonts/BarlowCondensed-Bold.ttf');
}

@font-face {
  font-family: 'BC-MEDIUM';
  src: url('./assets/fonts/BarlowCondensed-Medium.ttf');
}

@font-face {
  font-family: 'BC-REGULAR';
  src: url('./assets/fonts/BarlowCondensed-Regular.ttf');
}

@font-face {
  font-family: 'T-MONEY-BOLD';
  src: url('./assets/fonts/TmoneyRoundWindExtraBold.ttf');
}

body {
  margin: 0;
  font-family: 'Nanum Gothic', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a {
  font-family: 'NanumGothic' !important;
  color: '#4e4e4e' !important;
}

.shadow {
  box-shadow: 1px 2px 9px -6px rgba(0, 0, 0, 0.3);
}

/* FIXME ANTD Notificaition 위치 컴포넌트 내부 바인딩을 위한 속성
  변경필요하면 HENRY에게 문의좀 부탁드립니다.
*/
.ant-notification {
  position: absolute !important;
}

.w-100 {
  width: 100%;
}
.w-75 {
  width: 75%;
}
.w-50 {
  width: 50%;
}
.w-25 {
  width: 25%;
}

.h-100 {
  height: 100%;
}
.h-75 {
  height: 75%;
}
.h-50 {
  height: 50%;
}
.h-25 {
  height: 25%;
}

.text-center {
  text-align: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.text-black {
  color: black;
}



.cusorOver,
.hover-opacity {
  cursor: pointer;
}

.cusorOver:hover {
  background: #f1f1f1;
  opacity: 0.85;
}

.hover-opacity:hover {
  opacity: 0.7;
}